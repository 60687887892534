interface ArtPieceParameters {
  fileName: string,
  name?: string,
  description: string,
  forSale?: boolean,
  sold?: boolean,
  tags?: string
}


export class ArtPiece {
  name: string;
  fileName: string;
  description?: string;
  tags?: string;
  forSale: boolean;
  sold: boolean;

  constructor({
    fileName,
    name,
    description,
    forSale = false,
    sold,
    tags = ""
  }: ArtPieceParameters) {
    this.fileName = fileName;
    if (name) this.name = name;
    else this.name = this.fileName;
    if (description) this.description = description;
    this.tags = tags;
    this.forSale = forSale;
    this.sold = sold;
  }

  /* TODO: Load data from file */
  load(fileName: string) {
    this.description = "TODO: loaded from file"
  }

  /* Return whether the object passes through the filter string. */
  filter(filterText: string) {
    return this.tags.includes(filterText.toLowerCase());
  }

  getForSaleStatus() {
    if (this.tags.includes('sold')) return 'sold';
    else if (this.tags.includes('for sale')) return 'for sale';
    else return false;
  }
}
