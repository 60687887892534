import { Injectable } from '@angular/core';
import { Album } from './album.class';
import { ALBUMS } from './albums';

@Injectable({
  providedIn: 'root'
})
export class AlbumService {
  albums: Album[];

  constructor() {
    this.albums = ALBUMS;
  }

  async getAlbum(name): Promise<Album> {
    return this.albums.find(album => {
      return album.slug === name;
    });
  }
}
