import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MarkdownModule } from 'ngx-markdown';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './pages/index/index.component';
import { MenuPrimaryComponent } from './menu-primary/menu-primary.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { GalleryViewComponent } from './gallery-view/gallery-view.component';
import { SocialMediaBarComponent } from './social-media-bar/social-media-bar.component';
import { SocialMediaLinkComponent } from './social-media-link/social-media-link.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { ForSaleComponent } from './pages/for-sale/for-sale.component';
import { CommissionsComponent } from './pages/commissions/commissions.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SplitPipe } from './split.pipe';
import { ContactComponent } from './pages/contact/contact.component';

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    MenuPrimaryComponent,
    FooterComponent,
    HeaderComponent,
    GalleryViewComponent,
    SocialMediaBarComponent,
    SocialMediaLinkComponent,
    GalleryComponent,
    ForSaleComponent,
    CommissionsComponent,
    NotFoundComponent,
    SplitPipe,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    NgbModule,
    MarkdownModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
