import { Album } from './album.class';
import { ArtPiece } from './art-piece';

const ART_HIGHLIGHTS = {
  manticorb: new ArtPiece({ fileName: 'character-design/monstersanctuary-manticorb.png', name: 'Manticorb', description: 'Winning creature concept for Monster Sanctuary design contest' }),
  vyrmine: new ArtPiece({ fileName: 'character-design/kindredfates-vyrmine.png', name: 'Vyrmine', description: 'Creature concept for Kindred Fates design contest' }),
  ice_dragon: new ArtPiece({ fileName: "illustration/squishable-icedragonpillow.jpg", name: "Ice Dragon Pillow", description: "Winning Project Open Squish design submission for Squishable" }),
  coat_of_arms: new ArtPiece({fileName: "illustration/telefang-coatofarms.png", name: "Denjuu World Coat of Arms", description: "Heraldry-inspired Keitai Denjuu Telefang fanart design" }),
  dratini_plush: new ArtPiece({ fileName: 'plush/plush-dratini.jpg', name: 'Life-sized Dratini Plush', description: '6\'0" (1.8 m) long Pokémon-inspired fanart plush, model is Sarah Briggs' }),
  peacock_spider: new ArtPiece({ fileName: 'etc/pin-maratus1.jpg', name: 'Peacock Jumping Spider Pin', description: '1.25" soft enamel pin of Maratus volans' })
};

export const ALBUMS: Album[] = [
  {
    slug: 'highlights',
    name: 'Highlights',
    description: '',
    items: Object.values(ART_HIGHLIGHTS)
  },

  // Album: Character design
  {
    slug: 'character-design',
    name: 'Character Design',
    description: `Examples of creature designs for use in games and such.`,
    items: [
      new ArtPiece({ fileName: 'character-design/fakemon-tanuki.png', name: 'Tanuki-1', description: 'Pokémon-styled concept design' }),
      new ArtPiece({ fileName: 'character-design/kindredfates-vyrmine.png', name: 'Vyrmine', description: 'Creature concept for Kindred Fates design contest' }),
      new ArtPiece({ fileName: 'character-design/kindredfates-vypurr.png', name: 'Vypurr', description: 'Creature concept for Kindred Fates design contest' }),
      new ArtPiece({ fileName: 'character-design/legendarybitty-bitsheet.png', name: 'Little Bit', description: 'Character sheet for a personal project in development' }),
      new ArtPiece({ fileName: 'character-design/monstercrown-nighknit.png', name: 'Nighknit (Base)', description: 'Creature concept for Monster Crown' }),
      new ArtPiece({ fileName: 'character-design/monstercrown-nighknitv.png', name: 'Nighknit (Malicious)', description: 'Creature concept for Monster Crown' }),
      new ArtPiece({ fileName: 'character-design/monstersanctuary-brutus.png', name: 'Brutus', description: 'Winning creature concept for Monster Sanctuary design contest, original submission by Umaro' }),
      new ArtPiece({ fileName: 'character-design/monstersanctuary-conflagator.png', name: 'Conflagator', description: 'Creature concept for Monster Sanctuary design contest, original submission by Conan The Librarian & Yuggon' }),
      new ArtPiece({ fileName: 'character-design/monstersanctuary-manticorb.png', name: 'Manticorb', description: 'Winning creature concept for Monster Sanctuary design contest' }),
      new ArtPiece({ fileName: 'character-design/monstersanctuary-stolby.png', name: 'Stolby', description: 'Winning creature concept for Monster Sanctuary design contest' }),
      new ArtPiece({ fileName: 'character-design/monzukan.png', name: 'Monzu', description: 'Mascot character for the Twitter bot @Monzukan' }),
      new ArtPiece({ fileName: 'character-design/sarasinor.png', name: 'Sarasinor', description: 'Peaceful spider alien concept, original idea by Nieve Frost' }),
      new ArtPiece({ fileName: 'character-design/thewuxing-volcanospider.jpg', name: 'Volcano Spider', description: 'Creature concept for The Wu Xing' }),
    ]
  },

  //Album: Illustration
  {
    slug: 'illustration',
    name: 'Illustration',
    description: `Character illustrations and drawings.`,
    items: [
      new ArtPiece({
        "fileName": "illustration/businesscard.png",
        "name": "Business Card",
        "description": "Personal business card design",
        "tags": "business card spider candle purple racieb contact"
      }),
      new ArtPiece({
        "fileName": "illustration/heraldic-nubs.png",
        "name": "Heraldic Queen Nubs",
        "description": "Heraldry-inspired cat design"
      }),
      new ArtPiece({
        "fileName": "illustration/necrodancer-monstermash.png",
        "name": "Monster Mosh",
        "description": "Submission for Redbubble's Crypt of the Necrodancer fanart contest"
      }),
      new ArtPiece({
        "fileName": "illustration/spaceworld-collage.png",
        "name": "Space World '97",
        "description": "Digital watercolor fanart interpretations of beta Pokémon designs "
      }),
      new ArtPiece({
        "fileName": "illustration/spaceworld-showcase.png",
        "name": "Space World Showcase",
        "description": "Vector fanart of beta Pokémon in a Monsterology classroom"
      }),
      new ArtPiece({
        "fileName": "illustration/spaceworld-skatetiger.png",
        "name": "Skate Tiger '97",
        "description": "Vector fanart of beta Pokémon Kotora on a skateboard"
      }),
      new ArtPiece({
        "fileName": "illustration/squishable-chocolatestrawberry.png",
        "name": "Strawberry Friends Forever",
        "description": "Two-page illustration for the Squishable coloring book, Cinnamon Bun Dreams"
      }),
      new ArtPiece({
        "fileName": "illustration/squishable-icedragonpillow.jpg",
        "name": "Ice Dragon Pillow",
        "description": "Winning Project Open Squish design submission for Squishable"
      }),
      new ArtPiece({
        "fileName": "illustration/squishable-pancakes.png",
        "name": "Pancakes",
        "description": "Two-page illustration for the Squishable coloring book, Cinnamon Bun Dreams"
      }),
      new ArtPiece({
        "fileName": "illustration/telefang-coatofarms.png",
        "name": "Denjuu World Coat of Arms",
        "description": "Heraldry-inspired Keitai Denjuu Telefang fanart design"
      })
    ]
  },
  
  // Album: Plush design
  {
    slug: 'plush-design',
    name: 'Plush design',
    description: `Stuffed animal and soft sculpture design, including concept art and finished products.`,
    items: [
      new ArtPiece({ fileName: 'plush/plush-dratini.jpg', name: 'Life-sized Dratini Plush', description: '6\'0" (1.8 m) long Pokémon-inspired fanart plush, model is Sarah Briggs' }),
      new ArtPiece({ fileName: 'plush/plush-furret.jpg', name: 'Furret Plush', description: '3\'3" (1 m) long Pokémon-inspired fanart plush' }),
      new ArtPiece({ fileName: 'plush/plush-laz.png', name: 'Laz Plush Prototype', description: '8" (20 cm) tall Laz plush prototype for Monster Crown Kickstarter campaign' }),
      new ArtPiece({ fileName: 'plush/plush-pikachu.jpg', name: 'Classic Pikachu Plush', description: '1\'0" (30 cm) tall Pokémon-inspired fanart plush, officially licensed McCall\'s sewing pattern' }),
      new ArtPiece({ fileName: 'plush/squishable-rainbowjumpingspider.png', name: 'Mini Squishable Rainbow Jumping Spider', description: 'Winning Project Open Squish design submission for Squishable' })
    ]
  },

  // Album: Etc.
  {
    slug: 'etc',
    name: 'Etc.',
    description: `Miscellaneous product designs such as pins, charms, and jewelry.`,
    items: [
      new ArtPiece({ fileName: 'etc/pin-maratus1.jpg', name: 'Peacock Jumping Spider Pin', description: '1.35" soft enamel pin of Maratus volans' }),
      new ArtPiece({ fileName: 'etc/pin-maratus2.jpg', name: 'Rainbow Spider Pin', description: '1.35" soft enamel pin of Maratus robinsoni' }),
      new ArtPiece({ fileName: 'etc/charm-spacetiger.jpg', name: 'Space Tiger 97 Acrylic Charm', description: '2" double-sided acrylic charm' }),
      new ArtPiece({ fileName: 'etc/pin-missingno.png', name: 'Missing Numbers', description: '1.25" hard enamel pin set' })
    ]
  },
];
