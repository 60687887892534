import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {

  transform(value: string, separator?: string): any {
    if (!separator) separator = '.';
    let split_string = value.split(separator);
    return split_string;
  }
}
