import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-primary',
  templateUrl: './menu-primary.component.html',
  styleUrls: ['./menu-primary.component.scss']
})
export class MenuPrimaryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
