import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './pages/index/index.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { ForSaleComponent } from './pages/for-sale/for-sale.component';
import { CommissionsComponent } from './pages/commissions/commissions.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { GalleryViewComponent } from './gallery-view/gallery-view.component';
import { ContactComponent } from './pages/contact/contact.component';


const routes: Routes = [
  { path: '', component: IndexComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'gallery/:albumSlug', component: GalleryViewComponent },
  { path: 'for-sale', component: ForSaleComponent },
  { path: 'commissions', component: CommissionsComponent },
  { path: 'contact', component: ContactComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: "enabled", onSameUrlNavigation: "reload" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
