import { Component, OnInit, Input, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Album } from '../album.class';
import { AlbumService } from '../album.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ArtPiece } from '../art-piece';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-gallery-view',
  templateUrl: './gallery-view.component.html',
  styleUrls: ['./gallery-view.component.scss']
})
export class GalleryViewComponent implements OnInit {
  @Input() albumSlug: string;
  @Input() filter?: string = "";
  @Input() enableFilter?: boolean = false;
  @Input() enableTitle?: boolean = true;
  @Input() carouselMode?: boolean = false;

  album: Album;
  filteredAlbum: ArtPiece[];
  previewedImage: ArtPiece;
  index: number = 0;
  sub: Subscription;
  timer: ReturnType<typeof setTimeout>;

  constructor(private modalService: NgbModal, private albumService: AlbumService, private route: ActivatedRoute, private location: Location) {
  }

  ngOnInit() {
    // Load album from albumSlug param given in component
    this.loadAlbum(this.albumSlug);

    // Load album from slug param given in URL
    this.sub = this.route.params.subscribe(params => {
      const slug = params['albumSlug'];
      if (slug) {
        this.enableFilter = true;
        this.loadAlbum(slug);
      }
    });

    // TODO: if URL contains params, open modal with indicated pic
  }

  /** Load an album using the slug string given. */
  loadAlbum(slug: string) {
    this.albumSlug = slug;
    this.albumService.getAlbum(slug).then(album => {
      this.album = album;
      if (this.carouselMode){
        this.index = 0;
        this.filteredAlbum = this.album.items;
        this.previewedImage = this.filteredAlbum[0];
        this.timer = setInterval(() => { this.nextPicture(); }, 4000);
      }
    });
  }

  /** Action to perform when clicking the thumbnail image. */
  open(content, image) {
    this.previewedImage = image;

    if (!this.carouselMode) { // Normal mode: open modal
      this.modalService.open(content, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        scrollable: true,
        // size: 'lg'
      });
    }
    else { // Carousel mode: reset timer
      clearInterval(this.timer);
      this.timer = setInterval(() => { this.nextPicture(); }, 4000);
    }
    this.index = this.getImageIndex();
  }

  /* Read keypresses */
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    let key = event.code;
    if (key == "ArrowLeft") this.previousPicture();
    if (key == "ArrowRight") this.nextPicture();
  }

  /* Left key pressed in modal */
  previousPicture() {
    this.index--;
    if (this.index < 0) this.index = 0;
    this.previewedImage = this.filteredAlbum[this.index];
  }

  /* Right key pressed in modal */
  nextPicture() {
    this.index++;
    if (this.index >= this.filteredAlbum.length) {
      if (this.carouselMode) {
        this.index = 0;
      } else {
        this.index = this.filteredAlbum.length - 1;
      }
    }
    this.previewedImage = this.filteredAlbum[this.index];
  }

  /* Find index of current image */
  getImageIndex(): number {
    for (let i = 0; i < this.filteredAlbum.length; i++) {
      if (this.filteredAlbum[i] == this.previewedImage) {
        return i;
      }
    }
    return 0;
  }

  filterAlbum(text: string) {
    this.filteredAlbum = [];
    for (const i in this.album.items) {
      if (this.album.items[i].filter(text)) {
        this.filteredAlbum.push(this.album.items[i]);
      }
    }
    return this.filteredAlbum;
  }

}
