import { Component, OnInit } from '@angular/core';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

interface SocialMediaLink {
  name: string;
  icon: string;
  url: string;
}

@Component({
  selector: 'app-social-media-bar',
  templateUrl: './social-media-bar.component.html',
  styleUrls: ['./social-media-bar.component.scss']
})
export class SocialMediaBarComponent implements OnInit {
  socialMediaLinks: SocialMediaLink[];

  constructor() {
    this.socialMediaLinks = [
      { name: "Twitter: @RacieBeep", icon: "twitter-icon.png", url: "https://twitter.com/RacieBeep" },
      { name: "Etsy shop", icon: "etsy-icon.png", url: "https://etsy.com/shop/RacieB" },
      { name: "Redbubble shop", icon: "redbubble-icon.png", url: "https://redbubble.com/people/RacieB" },
      { name: "Email: racieb@gmail.com", icon: "email-icon.png", url: "mailto:racieb@gmail.com" },
    ]
  }

  ngOnInit() {
  }

}
